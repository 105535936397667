<template>
  <div class="static-content-wrapper">
    <h1>Please login to continue</h1>
    <p>
      If you are having trouble accessing the system
      <a class="secondary" href="mailto:gkruel@me.com" target="_blank"
        >send us an email</a
      >.
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    verifyProfile() {
      const userProfile = this.$store.getters["user/getUserProfile"];

      const hasProfile = Object.keys(userProfile).length === 0 ? false : true;

      return hasProfile;
    },
  },
  watch: {
    verifyProfile(hasProfile) {
      if (hasProfile) {
        this.$router.push({ name: "ownertrials" });
      }
    },
  },
};
</script>
